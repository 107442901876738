export const AUTH = {
  LOGIN: '/login'
};

export const HOME = {
  HOME: '/'
};

export const HOMEWORK_MANAGEMENT = {
  HOMEWORKS: '/homeworks',
  PREVIEW: '/homeworks/preview',
  HOMEWORK_DETAILS: '/homeworks/:id/:assignmentId/:classID',
  REPORTS: '/homeworks/reports/:assignmentId/:classId'
};

export const CLASSES = {
  CLASSES: '/classes',
  CLASS: '/classes/:id',
  REPORTS: '/classes/reports/:id'
};

export const STUDENTS = {
  STUDENTS: '/students',
  STUDENT: '/students/:id',
  STUDENT_HOMEWORK_DETAILS: '/students/homeworks/:studentId/:assignmentId',
  STUDENT_REPORTS: '/students/reports/:id'
};

export const DASHBOARD = {
  DASHBOARD: '/dashboard'
};

export const NOTIFICATIONS = {
  NOTIFICATIONS: '/notifications'
};

export const SETTINGS = {
  SETTINGS: '/settings'
};

export const ERROR = {
  ERROR: '/error/:errorStatus'
};
