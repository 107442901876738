module.exports = {
  theme: 'var(--color-theme)',
  bgBase: 'var(--color-bgBase)',
  content: 'var(--color-content)',
  grey: 'var(--color-grey)',
  grey20: 'var(--color-grey20)',
  grey30: 'var(--color-grey30)',
  grey40: 'var(--color-grey40)',
  greyLite: 'var(--color-greyLite)',
  primary: 'var(--color-primary)',
  errorLite: 'var(--color-errorLite)',
  primary10: 'var(--color-primary10)',
  primaryLite: 'var(--color-primaryLite)',
  primary0: 'var(--color-primary-0)',
  primary1: 'var(--color-primary-1)',
  primary2: 'var(--color-primary-2)',
  primary3: 'var(--color-primary-3)',
  primary4: 'var(--color-primary-4)',
  primary5: 'var(--color-primary-5)',
  primary6: 'var(--color-primary-6)',
  primary7: 'var(--color-primary-7)',
  primary8: 'var(--color-primary-8)',
  primary9: 'var(--color-primary-9)',
  grey0: 'var(--color-grey-0)',
  grey1: 'var(--color-grey-1)',
  grey2: 'var(--color-grey-2)',
  grey3: 'var(--color-grey-3)',
  grey4: 'var(--color-grey-4)',
  grey5: 'var(--color-grey-5)',
  grey6: 'var(--color-grey-6)',
  grey7: 'var(--color-grey-7)',
  grey8: 'var(--color-grey-8)',
  grey9: 'var(--color-grey-9)',
  grey10: 'var(--color-grey-10)',
  grey11: 'var(--color-grey-11)',
  neutral8: 'var(--color-neutral-8)',
  neutral6: 'var(--color-neutral-6)',
  error: 'var(--color-error)',
  warning: 'var(--color-warning)',
  warning50: 'var(--color-warning-50)',
  warning500: 'var(--color-warning-500)',
  warning600: 'var(--color-warning-600)',
  warning800: 'var(--color-warning-800)',
  success600: 'var(--color-success-600)',
  success900: 'var(--color-success-900)',
  success50: 'var(--color-success-50)',
  green: 'var(--color-green)',
  green700: 'var(--color-green-700)',
  greenLite: 'var(--color-greenLite)',
  green600: 'var(--color-green-600)',
  green100: 'var(--color-green-100)',
  green400: 'var(--color-green-400)',
  yellow: 'var(--color-yellow)',
  yellow50: 'var(--color-yellow-50)',
  yellow400: 'var(--color-yellow-400)',
  yellow75: 'var(--color-yellow-75)',
  yellow700: 'var(--color-yellow-700)',
  yellow900: 'var(--color-yellow-900)',
  yellowLite: 'var(--color-yellowLite)',
  yellow100: 'var(--color-yellow-100)',
  pink: 'var(--color-pink)',
  blue: 'var(--color-blue)',
  blueLite: 'var(--color-blueLite)',
  blueLite80: 'var(--color-blueLite-80)',
  blue600: 'var(--color-blue-600)',
  error700: 'var(--color-error-700)',
  error600: 'var(--color-error-600)',
  text900: 'var( --color-text-900)',
  blue50: 'var(--color-blue-50)',
  blue800: 'var(--color-blue-800)',
  blue500: 'var(--color-blue-500)',
  blue100: 'var(--color-blue-100)'
};
