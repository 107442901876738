import { Navigate, RouteObject } from 'react-router-dom';

import { ERROR, HOME, HOMEWORK_MANAGEMENT } from '@constants/routes';
import HomeworkManagementRoutes from '@features/homework-management/routes';
import DashboardRoutes from '@features/dashboard/routes';
import AuthRoutes from '@features/authentication/routes';
import ClassesRoutes from '@features/classes/routes';
import StudentsRoutes from '@features/students/routes';
import NotificationRoutes from '@features/notification/routes';
import MainLayoutWrapper from '@contexts/MainLayoutWrapper';
import SettingsRoutes from '@features/settings/routes';
import ErrorPage from '@features/error/pages/error-page/ErrorPage';

const PrivateRoutes: RouteObject[] = [
  AuthRoutes,
  {
    path: HOME.HOME,
    element: <Navigate to={HOMEWORK_MANAGEMENT.HOMEWORKS} /> // Re-route from '/' to '/order-management/orders'
  },
  {
    element: <MainLayoutWrapper />, // Outlet in MainLayout will be replaced by matching element from below
    children: [
      ...HomeworkManagementRoutes,
      ...DashboardRoutes,
      ...ClassesRoutes,
      ...StudentsRoutes,
      ...NotificationRoutes,
      ...SettingsRoutes
    ]
  },

  {
    path: ERROR.ERROR,
    element: <ErrorPage />
  },
  {
    path: '*',
    element: <Navigate to="/error/404" />
  }
];

export default PrivateRoutes;
