import clsx from 'clsx';

import AnswerStatusChip from '@components/answer-status-chip/AnswerStatusChip';
import { StudentHomeworkViewType } from '@features/homework-management/constants';
import { ReviewCardProps } from '@types';
import { AnswerStatus } from '@constants/answer';

import Button from '../button/Button';

const ReviewCard = (props: ReviewCardProps) => {
  const {
    className = '',
    handleResultView,
    question,
    questionNo,
    strictModalType
  } = props;

  const handleViewQuestionDetails = (type: StudentHomeworkViewType) => {
    if (handleResultView) handleResultView(type, question?.questionId);
  };
  return (
    <div
      className={clsx(
        'flex items-center gap-6 rounded-2xl border border-primary1 bg-white px-6 py-3 text-sm font-normal mobile:flex-col',
        className
      )}
    >
      <div className="flex grow items-center gap-6 mobile:w-full mobile:flex-wrap mobile:justify-between mobile:gap-2 tablet:justify-stretch tablet:gap-2">
        <div className="order-1 grow font-semibold mobile:grow-0 tablet:w-3/5 desktop:w-56">
          Question {(questionNo || 0) + 1}
        </div>
        <AnswerStatusChip
          status={question.result || AnswerStatus.NOT_STARTED}
          className="order-2 w-5/6 min-w-20 grow mobile:order-3 mobile:w-20 mobile:grow-0 mobile:text-wrap"
        />
        <div className="order-3 flex grow items-center mobile:order-2 mobile:grow-0">
          <div className="me-1">Mark:</div>
          <div className="font-semibold">
            {question.mark}/{question.maximumMark}
          </div>
        </div>
      </div>
      <div className="flex gap-2 align-middle mobile:w-full">
        {(!strictModalType ||
          strictModalType === StudentHomeworkViewType.RESULT_VIEW) && (
          <Button
            variant="secondary"
            className="min-w-fit mobile:w-1/2 bg-theme"
            label="Result"
            handleClick={() =>
              handleViewQuestionDetails(StudentHomeworkViewType.RESULT_VIEW)
            }
            disabled={!question.result}
          />
        )}
        {(!strictModalType ||
          strictModalType === StudentHomeworkViewType.STUDENT_VIEW) && (
          <Button
            variant="secondary"
            className="min-w-fit mobile:w-1/2 bg-theme"
            label="Student View"
            handleClick={() =>
              handleViewQuestionDetails(StudentHomeworkViewType.STUDENT_VIEW)
            }
            disabled={!question.result}
          />
        )}
      </div>
    </div>
  );
};

export default ReviewCard;
