import clsx from 'clsx';

import { ChipProps } from '@types';

import Icon from '../icon/Icon';

const Chip = ({
  label,
  className = '',
  onRemove,
  handleChipClick,
  isCloseIconRequired = true,
  labelStyle = 'max-w-80',
  isTruncate = true
}: ChipProps) => (
  <div
    role="presentation"
    onClick={(e) => handleChipClick?.(label, e)}
    className={clsx(
      'flex h-7 w-fit items-center rounded-2xl px-2 py-1 text-xs font-medium text-content desktop:p-4',
      className
    )}
  >
    <div
      className={`${isTruncate ? 'truncate break-words' : 'w-fit'} ${
        labelStyle || ''
      }`}
    >
      {label}
    </div>
    {isCloseIconRequired && onRemove && (
      <div
        role="presentation"
        onClick={onRemove}
        className="ml-2 cursor-pointer"
      >
        <Icon name="close" />
      </div>
    )}
  </div>
);

export default Chip;
