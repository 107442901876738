import { useNavigate } from 'react-router-dom';

import useScreenSize from '@hooks/screenSize';
import CustomTooltip from '@components/tooltip/Tooltip';

import Icon from '../icon/Icon';
import Button from '../button/Button';
import { PageTitleProps } from './type';

const PageTitle = (props: PageTitleProps) => {
  const {
    title,
    description,
    primaryButtonLabel,
    primaryButtonIcon,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    secondaryButtonLabel,
    primaryButtonIconStyle,
    primaryButtonStroke,
    className,
    isPrimaryButtonDisabled,
    isPrimaryButtonLoading,
    secondaryButtonIcon,
    secondaryButtonIconStyle,
    secondaryButtonStroke,
    isSecondaryButtonDisabled,
    isBackButtonEnabled = false,
    primaryButtonClassName,
    secondaryButtonClassName,
    primaryButtonToolTipLabel,
    secondaryButtonTooltipLabel
  } = props;

  const { width } = useScreenSize();
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const primaryButton = () => (
    <>
      <Button
        dataToolTipId="page-title-primary-tooltip"
        label={primaryButtonLabel}
        handleClick={onPrimaryButtonClick}
        variant="primary"
        size={width > 1024 ? 'medium' : 'small'}
        rightIconName={primaryButtonIcon}
        rightIconStyle={primaryButtonIconStyle}
        rightIconStroke={primaryButtonStroke || ''}
        disabled={isPrimaryButtonDisabled || false}
        loading={isPrimaryButtonLoading || false}
        className={primaryButtonClassName || ''}
      />
      {primaryButtonToolTipLabel && (
        <CustomTooltip
          id="page-title-primary-tooltip"
          noArrow={false}
          className="text-sm text-grey10"
        >
          {primaryButtonToolTipLabel}
        </CustomTooltip>
      )}
    </>
  );

  const secondaryButton = () => (
    <>
      <Button
        dataToolTipId="page-title-secondary-tooltip"
        label={secondaryButtonLabel}
        handleClick={onSecondaryButtonClick}
        variant="secondary"
        size={width > 1024 ? 'medium' : 'small'}
        rightIconName={secondaryButtonIcon}
        rightIconStyle={secondaryButtonIconStyle}
        rightIconStroke={secondaryButtonStroke || ''}
        disabled={isSecondaryButtonDisabled || false}
        className={secondaryButtonClassName || ''}
      />
      {secondaryButtonTooltipLabel && (
        <CustomTooltip
          id="page-title-secondary-tooltip"
          variant="light"
          noArrow={false}
          className="rounded-lg border !px-6 !py-3 "
        >
          {secondaryButtonTooltipLabel}
        </CustomTooltip>
      )}
    </>
  );

  return (
    <div className={`flex items-center justify-between ${className || ''}`}>
      <div className="flex gap-3 mobile:gap-2">
        {isBackButtonEnabled && (
          <Icon
            name="back"
            size={width > 1024 ? 'medium' : '24'}
            onClick={handleBackClick}
            className="cursor-pointer sm:mt-2"
          />
        )}
        <div>
          <h1 className="text-lg font-semibold text-content tablet:text-2xl desktop:text-2xl">
            {title}
          </h1>
          <p className="mt-2 text-sm font-normal text-grey10">{description}</p>
        </div>
      </div>
      <div className="flex items-center gap-4">
        {secondaryButtonLabel && secondaryButton()}
        {primaryButtonLabel && primaryButton()}
      </div>
    </div>
  );
};

export default PageTitle;
