import { Suspense, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { SideNavBar, Spinner, Topbar } from '@components';
import { getLocalStorageItem } from '@utils/storage';
import { AUTH } from '@constants/routes';
import { useGetUserDetailsQuery } from '@features/authentication/queries/AuthQuery';
import { setCurrentUser } from '@features/authentication/userSlice';
import { useGetClassesForTeachersDropdownQuery } from '@features/homework-management/queries/HomeworkQuery';
import { updateClassDetails } from '@features/homework-management/slices/homeworkSlice';

const MainLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data } = useGetUserDetailsQuery();
  const { data: classDetails } = useGetClassesForTeachersDropdownQuery({
    param: ''
  });

  const token = getLocalStorageItem('token');

  useEffect(() => {
    dispatch(setCurrentUser({ user: data?.data }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    dispatch(updateClassDetails(classDetails?.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classDetails]);

  useEffect(() => {
    if (!token) navigate(AUTH.LOGIN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex h-screen w-screen overflow-hidden">
      <div className="size-full">
        <div className="hidden sm:block">
          <SideNavBar userDetails={data?.data} />
        </div>
        <div className="sm:hidden">
          <Topbar />
        </div>
        <div className="h-[calc(100%-60px)] overflow-y-auto bg-primaryLite sm:h-screen">
          <div className="wrapper" id="main-layout-wrapper">
            <Suspense
              fallback={
                <div className="absolute inset-0 top-1/2 flex size-full -translate-y-1/2 items-center justify-center">
                  <Spinner />
                </div>
              }
            >
              <Outlet />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
