import React, { FC, memo } from 'react';

import MainLayout from '@layouts/main-layout/MainLayout';
import { SirenProvider } from '@sirenapp/react-inbox';
import { getLocalStorageItem } from '@utils/storage';

const MainLayoutWrapper: FC<any> = () => {
  const config = {
    recipientId: getLocalStorageItem('siren_inapp_recipientId') || '',
    userToken: getLocalStorageItem('siren_inapp_token') || ''
  };
  return (
    <SirenProvider config={config}>
      <MainLayout />
    </SirenProvider>
  );
};

export default memo(MainLayoutWrapper);
